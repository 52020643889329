.App {
  
}

.ltr {
    text-align: left;
  }
  
  .rtl {
    text-align: right;
  }
  
  .editor-placeholder {
    color: #999;
    overflow: hidden;
    position: absolute;
    top: 15px;
    left: 15px;
    user-select: none;
    pointer-events: none;
  }
  
  .editor-paragraph {
    margin: 0 0 15px 0;
    position: relative;
  }

  .squareContainer {
    width:60px;
    height:60px;
    display:grid;
    grid-template-columns:repeat(3, 1fr);
    gap:0.9rem;
    transform:rotate(-45deg);
  }
  
  .square {

    display:grid;
    place-items:center;
    border-radius:50%;
    animation:load 1.6s ease infinite;
  }
  
  @keyframes load {
    0% {
      transform:scale(1);
      background-color:var(--color);
    }
    50% {
      transform:scale(0);
      background-color:var(--color);
    }
    100% {
      transform:scale(1);
      background-color:var(--color);
    }
  }
  
  .one {
    
  }
  
  .two {
    animation-delay:0.1s;
    
  }
  
  
  .three {
    animation-delay:0.2s;
   
  }
  
  
  .four {
    animation-delay:0.3s;
    
  }
  
  
  .five {
    animation-delay:0.4s;
   
  }

  .green {
    --color:#04CF6A;
  }

  .mid {
    --color:#4383A9;
  }

  .purple {
    --color:#7F3BE6;
  }


  .cls-1 {
    fill: url(#linear-gradient);
  }

  .cls-2 {
    fill: url(#linear-gradient-3);
  }

  .cls-3 {
    fill: url(#linear-gradient-4);
  }

  .cls-4 {
    fill: url(#linear-gradient-2);
  }

  .cls-5 {
    fill: url(#linear-gradient-5);
  }


  .loading-bar {
    background: linear-gradient(270deg, #7f3ae6, #14bc79, #7f3ae6);
    background-size: 600% 600%;
    -webkit-animation: AnimationName 3s ease infinite;
    -moz-animation: AnimationName 3s ease infinite;
    animation: AnimationName 3s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}





/* SCROLL TABLE SELECTORS */ 
/* TODO: Figure out how to put these into the tailwind styled components */
/* safari and ios need the tfoot itself to be position:sticky also */
.scrollTable tfoot,
tfoot th,
tfoot td {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  

  z-index:4;
}


.scrollTable thead th:first-child,
.scrollTable tfoot th:first-child {
  z-index: 5;
}



.tag-list {
  width: 30rem;
  max-width: 90vw;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  gap: 1rem 0;
  position: relative;
  padding: 1.5rem 0;
  overflow: hidden;
}

.loop-slider {
  .inner {
    display: flex;
    width: fit-content;
    animation-name: loop;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: var(--direction);
    animation-duration: var(--duration);
  }
}

.tag {
  display: flex;
  align-items: center;
  gap: 0 0.2rem;
  color: #e2e8f0;
  font-size: 0.9rem;
  background-color: #999999;
  border-radius: 0.4rem;
  padding: 0.7rem 1rem;
  margin-right: 1rem; 
  
  span {
    font-size: 1.2rem;
    color: #64748b;
  }
}

.fade {
  pointer-events: none;
  background: linear-gradient(90deg, #fff, transparent 30%, transparent 70%, #fff);
  position: absolute;
  inset: 0;
}

@keyframes loop {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}


.tinyDisplay p {
  
}

.tinyDisplay a {
  text-decoration: underline;
  color: blue;
  
}

.tinyDisplay ul {
  list-style-type: circle !important;
  list-style-position: outside;
}

.tinyDisplay li {
  list-style-type: circle !important;
  list-style-position: outside;
}